import React from 'react';
import MoreOptionsIcon from './more_options_icon.js.jsx';

export default class LeaseIndexModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = { ...this.props };

    this.populateAllLeasesModal = this.populateAllLeasesModal.bind(this);
  }

  componentDidMount() {
    this.props.parentComponent.updateAllLeasesModal = (newProps) => {
      this.setState(newProps);
    };
  }

  populateAllLeasesModal(type, id) {
    startLoadingSpinner('lease-index-section');
    var that = this;
    let url;

    if (type == 'unit') {
      url = '/units/' + id + '/all_leases_redesign';
    } else {
      url = '/tenants/' + id + '/leases_redesign';
    }

    $.ajax({
      url: url,
      type: 'GET',
      dataType: 'JSON',
      success: (data) => {
        if (type == 'unit') {
          data.unitId = id;
        }
        that.setState(data, function () {
          stopLoadingSpinner('lease-index-section');
        });
      },
    });
  }

  render() {
    var leaseIndex = [];
    var idx = 0;
    var that = this;
    this.state.allLeases.forEach((lease) => {
      leaseIndex.push(
        <LeaseIndexDetails
          key={'lease' + lease.id}
          id={lease.id}
          unit={lease.unit}
          building={lease.building}
          tenantList={lease.tenantList}
          attachments={lease.attachments}
          info={lease.leaseInfo}
          transactionSchedules={lease.transactionSchedules}
          securityDepositTransactions={lease.securityDepositTransactions}
          securityDepositHeld={lease.securityDepositHeld}
          unitId={that.props.unitId}
          current={lease.current}
          parentComponent={that.props.parentComponent}
          parentObjectId={lease.parentObjectId}
          templateId={lease.templateId}
          entityId={that.props.parentComponent.state.selectedEntityId}
          populateAllLeasesModal={that.populateAllLeasesModal}
        />
      );
      leaseIndex.push(
        <div key={'divider' + idx} className="mdc-dialog__body"></div>
      );
      idx += 1;
    });

    if (leaseIndex.length == 0) {
      leaseIndex.push(
        <h2 key={leaseIndex.length}>
          There are no leases currently associated with this unit.
        </h2>
      );
    }

    let unitOrTenant = this.props.unitId ? 'Unit' : 'Tenant';

    return (
      <aside
        id="mdc-dialog-allleases1"
        className="mdc-dialog mdc-dialog--open"
        role="alertdialog"
        aria-hidden="true"
        aria-labelledby="mdc-dialog-default-label"
        aria-describedby="mdc-dialog-default-description"
      >
        <div className="mdc-dialog__surface" id="lease-index-section">
          <header className="mdc-dialog__header pm-bg-color--blue1">
            <h2
              id="mdc-dialog-default-label"
              className="mdc-dialog__header__title"
            >
              All Leases for {unitOrTenant}
            </h2>
          </header>
          <section className="mdc-dialog__body mdc-dialog__body--scrollable">
            {leaseIndex}
          </section>
          <footer className="mdc-dialog__footer">
            <button
              type="button"
              className="mdc-button pm-text-color--grey5 mdc-dialog__footer__button mdc-dialog__footer__button--cancelPM close-all-leases-button"
            >
              Close
            </button>
          </footer>
        </div>
        <div className="mdc-dialog__backdrop"></div>
      </aside>
    );
  }
}

class LeaseIndexDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = { ...this.props };
    this.editLease = this.editLease.bind(this);
    this.updateLease = this.updateLease.bind(this);
    this.handleEditTransactionSchedule =
      this.handleEditTransactionSchedule.bind(this);
    this.readyEditSchedule = this.readyEditSchedule.bind(this);
    this.cancelEditSchedule = this.cancelEditSchedule.bind(this);
    this.submitEditSchedule = this.submitEditSchedule.bind(this);
    this.expandAttachments = this.expandAttachments.bind(this);
    this.openReinstateLease = this.openReinstateLease.bind(this);
    this.printMoveOutStatement = this.printMoveOutStatement.bind(this);
    this.reinstateLease = this.reinstateLease.bind(this);
    this.closeLeaseDetails = this.closeLeaseDetails.bind(this);
    this.destroyLease = this.destroyLease.bind(this);
    this.openDestroyLeasePrompt = this.openDestroyLeasePrompt.bind(this);
    this.sortTransactionSchedulesOnClick =
      this.sortTransactionSchedulesOnClick.bind(this);
    this.sortTransactionSchedules = this.sortTransactionSchedules.bind(this);
  }

  componentDidMount() {
    this.sortTransactionSchedules('name');
  }

  editLease() {
    startLoadingSpinner('lease-index-section');
    var that = this;
    $.ajax({
      url: '/leases/' + that.props.id + '/edit_redesign/',
      dataType: 'JSON',
      success: function (data) {
        stopLoadingSpinner('lease-index-section');
        if (data.errors == null) {
          initDialog(data.html, { acceptFunction: that.updateLease });
        } else {
          alert(data.errors);
        }
      },
    });
  }

  updateLease(closeDialogCallback) {
    startLoadingSpinner('edit-lease-section');
    var that = this;
    var form = $('#edit-lease-redesign');
    $.ajax({
      url: $(form).attr('action') + '?id=' + that.props.id,
      type: 'PUT',
      dataType: 'JSON',
      data: $(form).serialize(),
      success: function (data) {
        if (data.errors == null) {
          closeDialogCallback();
        } else {
          alert(data.errors);
        }
        stopLoadingSpinner('edit-lease-section');
      },
      complete: function () {
        enableSubmit();
      },
    });
  }

  expandLeaseDetails(event) {
    var modalexpToggleParent = $(event.target).closest('.pm-doclist--doc')[0];
    var modalexpToggleDiv = $(modalexpToggleParent).children(
      '.pm-doclist--modalexp'
    )[0];
    $(modalexpToggleDiv).removeClass('pm-closed');
  }

  closeLeaseDetails(event) {
    var modalexpToggleDiv = $(event.target).closest('.pm-doclist--modalexp')[0];
    $(modalexpToggleDiv).addClass('pm-closed');
  }

  cancelEditSchedule(closeDialogCallback) {
    closeDialogCallback();
  }

  readyEditSchedule(dialogElement) {
    $('input[type=radio][name*=schedule_type]', dialogElement).change(
      function () {
        $('.nested-schedule-field', dialogElement).hide();
        var scheduleType = this.value;
        $(
          "div[data-schedule-type='" + scheduleType + "']",
          dialogElement
        ).show();
      }
    );
    $('input[type=radio][name*=schedule_type]:checked').change();

    $(
      'input[type=radio][name*=days_advance_generate_charge_type]',
      dialogElement
    ).change(function () {
      var textFieldId = $(this).data('text-field-id');
      $('.days-advance-field', dialogElement).attr('disabled', true);
      $('#' + textFieldId, dialogElement).removeAttr('disabled');
    });
    $(
      'input[type=radio][name*=days_advance_generate_charge_type]:checked'
    ).change();

    $('input[type=radio][name*=due_date_type]', dialogElement).change(
      function () {
        var textFieldId = $(this).data('text-field-id');
        $('.due-date-type-field', dialogElement).attr('disabled', true);
        $('#' + textFieldId, dialogElement).removeAttr('disabled');
      }
    );
    $('input[type=radio][name*=due_date_type]:checked').change();
  }

  submitEditSchedule(closeDialogCallback) {
    startLoadingSpinner('edit-schedule-section');
    var that = this;
    $('#edit-schedule-form')
      .off('submit')
      .submit(function (event) {
        event.preventDefault();
        event.stopImmediatePropagation();

        $.ajax({
          type: 'POST',
          url: $('#edit-schedule-form').attr('action'),
          data: $('#edit-schedule-form').serialize(),
          success: function (data) {
            if (data.errors == null) {
              var newSchedule = returnInfoForAllLease();
              var transactionSchedules = that.state.transactionSchedules;
              transactionSchedules.map(function (schedule) {
                if (schedule.id == newSchedule.id) {
                  schedule.name = newSchedule.name;
                  schedule.startDate = newSchedule.startDate;
                  schedule.endDate = newSchedule.monthToMonth
                    ? 'Month to Month'
                    : newSchedule.endDate;
                }
                return schedule;
              });

              that.setState({ transactionSchedules: transactionSchedules });
              if (that.scheduleSort) {
                that.scheduleSort = null;
              } else {
                that.scheduleSort = that.scheduleLastSorted;
              }
              that.sortTransactionSchedules(that.scheduleLastSorted);
              closeDialogCallback();
            } else {
              alert(data.errors);
            }
            stopLoadingSpinner('edit-schedule-section');
          },
          complete: function () {
            enableSubmit();
          },
        });
      })
      .submit();
  }

  handleEditTransactionSchedule(scheduleId) {
    var that = this;
    return function () {
      $.ajax({
        url: '/schedule_edit_redesign',
        data: { id: scheduleId, lease_id: that.props.id },
        type: 'GET',
        dataType: 'JSON',
        success: function (data) {
          if (data.errors == null) {
            initDialog(data.html, {
              acceptFunction: that.submitEditSchedule,
              cancelFunction: that.cancelEditSchedule,
              readyFunction: that.readyEditSchedule,
            });
          } else {
            alert(data.errors);
          }
        },
      });
    };
  }

  handleTenantClick(tenantId) {
    window.location.href = '/accounting/tenants?entity_id=' + tenantId;
  }

  expandAttachments() {
    startLoadingSpinner('lease-index-section');
    $.ajax({
      url: '/attachment_redesign',
      type: 'GET',
      dataType: 'JSON',
      data: { parent_id: this.props.id, my_parent: 'lease' },
      success: (data) => {
        initDialog(data.html);
        stopLoadingSpinner('lease-index-section');
      },
    });
  }

  openReinstateLease() {
    startLoadingSpinner('lease-index-section');
    var that = this;
    $.ajax({
      url: '/leases/' + that.props.id + '/reinstate_redesign',
      type: 'GET',
      data: { unit_id: that.props.unitId },
      dataType: 'JSON',
      success: function (data) {
        stopLoadingSpinner('lease-index-section');
        initDialog(data.html, { acceptFunction: that.reinstateLease });
      },
    });
  }

  printMoveOutStatement() {
    var that = this;
    $.ajax({
      url:
        '/communication_templates/' +
        that.state.templateId +
        '/print_redesign/' +
        that.state.parentObjectId,
      dataType: 'JSON',
      type: 'GET',
      success: function (data) {
        window.open(data.url);
      },
    });
  }

  reinstateLease(closeDialogCallback) {
    var that = this;
    var form = $('#submit-lease-redesign');
    $.ajax({
      url: '/leases/' + that.props.id + '/update_redesign',
      type: 'PUT',
      dataType: 'JSON',
      data: $(form).serialize(),
      success: function (data) {
        if (data.errors == null) {
          closeDialogCallback();
          if (data.automatic_action_errors) {
            alert(data.automatic_action_errors);
          }
        } else {
          alert(data.errors);
        }
      },
      complete: function () {
        enableSubmit();
      },
    });
  }

  openDestroyLeasePrompt() {
    startLoadingSpinner('lease-index-section');
    var that = this;
    $.ajax({
      url: '/leases/' + that.props.id + '/destroy_prompt/',
      dataType: 'JSON',
      success: function (data) {
        stopLoadingSpinner('lease-index-section');
        if (data.errors == null) {
          initDialog(data.html, { acceptFunction: that.destroyLease });
        } else {
          alert(data.errors);
        }
      },
    });
  }

  destroyLease(closeDialogCallback) {
    startLoadingSpinner('destroy-lease-section');
    var that = this;
    var form = $('#destroy-lease-redesign');
    $.ajax({
      url: '/leases/' + that.props.id + '/destroy_redesign/',
      type: 'PUT',
      dataType: 'JSON',
      data: $(form).serialize(),
      success: function (data) {
        if (data.errors == null) {
          closeDialogCallback();
          if (that.props.unitId) {
            that.props.populateAllLeasesModal('unit', that.props.unitId);
          } else {
            that.props.populateAllLeasesModal('entity', that.props.entityId);
          }
        } else {
          alert(data.errors);
        }
        stopLoadingSpinner('destroy-lease-section');
      },
      complete: function () {
        enableSubmit();
      },
    });
  }

  sortTransactionSchedulesOnClick(e) {
    this.sortTransactionSchedules(e.currentTarget.dataset.sortField);
  }

  sortTransactionSchedules(sortField) {
    const that = this;
    const currentlySorted = that.scheduleSort == sortField;

    let tmpTransactionSchedules = deepCopyArray(
      that.state.transactionSchedules
    );

    switch (sortField) {
      case 'name':
        tmpTransactionSchedules = tmpTransactionSchedules.sort(function (a, b) {
          if (a.name.toLowerCase() < b.name.toLowerCase()) {
            return currentlySorted ? 1 : -1;
          } else if (a.name.toLowerCase() > b.name.toLowerCase()) {
            return currentlySorted ? -1 : 1;
          }
          return 0;
        });
        break;
      case 'start_date':
        tmpTransactionSchedules = tmpTransactionSchedules.sort(function (a, b) {
          const dateValOne = new Date(a.startDate);
          const dateValTwo = new Date(b.startDate);
          if (dateValOne < dateValTwo) {
            return currentlySorted ? 1 : -1;
          } else if (dateValOne > dateValTwo) {
            return currentlySorted ? -1 : 1;
          }
          return 0;
        });
        break;
      case 'end_date':
        tmpTransactionSchedules = tmpTransactionSchedules.sort(function (a, b) {
          const dateValOne =
            a.endDate == 'Month to Month' ? new Date(0) : new Date(a.endDate);
          const dateValTwo =
            b.endDate == 'Month to Month' ? new Date(0) : new Date(b.endDate);
          if (dateValOne < dateValTwo) {
            return currentlySorted ? 1 : -1;
          } else if (dateValOne > dateValTwo) {
            return currentlySorted ? -1 : 1;
          }
          return 0;
        });
        break;
      case 'transaction_type':
        tmpTransactionSchedules = tmpTransactionSchedules.sort(function (a, b) {
          if (a.transactionType < b.transactionType) {
            return currentlySorted ? 1 : -1;
          } else if (a.transactionType > b.transactionType) {
            return currentlySorted ? -1 : 1;
          }
          return 0;
        });
        break;
      case 'item_types':
        tmpTransactionSchedules = tmpTransactionSchedules.sort(function (a, b) {
          if (a.itemTypes.toLowerCase() < b.itemTypes.toLowerCase()) {
            return currentlySorted ? 1 : -1;
          } else if (a.itemTypes.toLowerCase() > b.itemTypes.toLowerCase()) {
            return currentlySorted ? -1 : 1;
          }
          return 0;
        });
        break;
      case 'amount':
        tmpTransactionSchedules = tmpTransactionSchedules.sort(function (a, b) {
          if (a.amount < b.amount) {
            return currentlySorted ? 1 : -1;
          } else if (a.amount > b.amount) {
            return currentlySorted ? -1 : 1;
          }
          return 0;
        });
    }

    if (currentlySorted) {
      that.scheduleSort = null;
    } else {
      that.scheduleSort = sortField;
    }
    that.scheduleLastSorted = sortField;

    that.setState({ transactionSchedules: tmpTransactionSchedules });
  }

  render() {
    var lease = this;
    var attachments = lease.props.attachments.map(function (att) {
      return (
        <div
          key={'leaseAttachment' + att.id}
          className="pm-email-attachments pm-flex pm-flex-grow"
        >
          <button
            disabled
            className="mdc-button mdc-button-icon mdc-button-icon--large"
          >
            <i className="mdi mdi-attachment"></i>
          </button>
          <div className="pm-attachment">
            <a href={`/generate_signed_url/${att.id}`}>{att.name}</a>
          </div>
        </div>
      );
    });
    var transactionSchedules = lease.state.transactionSchedules.map(
      function (schedule) {
        return (
          <tr key={'scheduleDetailRow' + schedule.id}>
            <td className="schedule-name">{schedule.name}</td>
            <td className="schedule-start-date">{schedule.startDate}</td>
            <td className="schedule-end-date">{schedule.endDate}</td>
            <td className="schedule-transaction-type">
              {schedule.transactionType}
            </td>
            <td className="schedule-item-types">{schedule.itemTypes}</td>
            <td className="schedule-amount">${schedule.amount}</td>
            <td>
              <button
                className="edit-transaction-schedule-button mdc-button mdc-button--compact pm-text-color--blue1"
                data-mdc-auto-init="MDCRipple"
                onClick={lease.handleEditTransactionSchedule(schedule.id)}
              >
                Edit
              </button>
            </td>
          </tr>
        );
      }
    );
    var securityDepositTransactions =
      lease.state.securityDepositTransactions.map(function (transaction) {
        return (
          <tr
            key={
              'depositDetailRow' + transaction.transaction_type + transaction.id
            }
          >
            <td className="deposit-transaction-date">{transaction.date}</td>
            <td className="deposit-transaction-transaction-type">
              {transaction.transaction_type}
            </td>
            <td className="deposit-transaction-tenant">
              {transaction.tenant_name}
            </td>
            <td className="deposit-transaction-amount">
              {currencyWithCommas(transaction.amount, true, true)}
            </td>
          </tr>
        );
      });

    var menuItems = [
      {
        text: 'Lease Details',
        onClick: lease.expandLeaseDetails,
        testingId: 'lease-details',
      },
      {
        text: 'Attachments (' + attachments.length + ')',
        onClick: lease.expandAttachments,
        testingId: 'lease-attachments',
      },
      {
        text: this.props.current ? 'Edit Lease' : 'Reinstate Lease',
        onClick: this.props.current
          ? lease.editLease
          : lease.openReinstateLease,
        testingId: 'edit-reinstate-lease',
      },
      {
        text: 'Delete Lease',
        onClick: lease.openDestroyLeasePrompt,
        testingId: 'delete-lease',
      },
    ];

    if (!this.props.current)
      menuItems.splice(2, 0, {
        text: 'Print Move Out Statement',
        onClick: lease.printMoveOutStatement,
        testingId: 'move-out-statement',
      });

    var listOfTenants = lease.props.tenantList.map((tenant, idx) => {
      return (
        <span
          key={'tenantList' + idx}
          onClick={lease.handleTenantClick.bind(this, tenant.id)}
          style={{ marginRight: 15, cursor: 'pointer' }}
        >
          {tenant.name}
          <div className="pm-label">{tenant.role}</div>
        </span>
      );
    });

    var tenantNameList = lease.props.tenantList.map((tenant) => {
      return tenant.name;
    });

    const unitName =
      lease.props.unit !== null
        ? `${lease.props.building.replace(
            / +/g,
            ' '
          )} #${lease.props.unit.replace(/ +/g, ' ')}`
        : lease.props.building.replace(/ +/g, ' ');

    return (
      <div id="mdc-dialog-lease-index" data-test-current={lease.props.current}>
        <h2>{listOfTenants}</h2>
        <span className="pm-flex">
          <label className="lease-term-label">Lease Term </label>
        </span>
        <h4 className="all-leases-lease-dates">
          <span className="lease-start-date">{lease.state.info.startDate}</span>{' '}
          - <span className="lease-end-date">{lease.state.info.endDate}</span>
        </h4>
        <div className="pm-doclist pm-border-top--black06">
          <div
            className="pm-flex pm-flex-align-center pm-doclist--row pm-doclist--doc pm-padding-left-none pm-padding-right-none"
            data-unit-name={unitName}
          >
            <div className="pm-doclist--desc pm-flex pm-flex-col pm-flex-justify-center pm-flex-grow">
              <h4
                className="lease-name"
                data-test-building-name={lease.props.building.replace(
                  /\s+/g,
                  ' '
                )}
                data-test-unit-number={lease.props.unit.replace(/\s+/g, ' ')}
              >
                {lease.props.building}
                {lease.props.unit ? ` #${lease.props.unit}` : ''}
              </h4>
              {attachments}
            </div>
            <MoreOptionsIcon menuItems={menuItems} />
            <div className="pm-doclist--modalexp pm-closed">
              <div className="pm-doclist--modalexp-inner">
                <div className="pm-doclist--modalexp-head pm-flex pm-flex-space-between pm-flex-align-center">
                  <h3>Lease Details</h3>
                  <button
                    className="mdc-button mdc-button-icon pm-text-color--grey6 pm-modalexp-close"
                    onClick={lease.closeLeaseDetails}
                  >
                    <i className="mdi mdi-close"></i>
                  </button>
                </div>
                <div className="pm-doclist--modalexp-body">
                  <div className="pm-padding-16 pm-padding-bottom-none">
                    <div className="pm-flex pm-flex-2">
                      <div className="pm-flex pm-flex-col">
                        <div className="pm-display">
                          <span className="mdc-expansion__item-title">
                            Unit #
                          </span>
                          <span className="mdc-expansion__item-value unit-number">
                            {lease.props.unit}
                          </span>
                        </div>
                      </div>
                      <div className="pm-flex pm-flex-col">
                        <div className="pm-display">
                          <span className="mdc-expansion__item-title">
                            Building
                          </span>
                          <span className="mdc-expansion__item-value building-name">
                            {lease.props.building}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="pm-flex pm-flex-2">
                      <div className="pm-flex pm-flex-col">
                        <div className="pm-display">
                          <span className="mdc-expansion__item-title">
                            Primary Tenant
                          </span>
                          <span className="mdc-expansion__item-value">
                            {lease.state.info.primaryTenant}
                          </span>
                        </div>
                      </div>
                      <div className="pm-flex pm-flex-col">
                        <div className="pm-display">
                          <span className="mdc-expansion__item-title">
                            Online Access
                          </span>
                          <span className="mdc-expansion__item-value">
                            {lease.state.info.onlineAccess}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="pm-flex pm-flex-2">
                      <div className="pm-flex pm-flex-col">
                        <div className="pm-display">
                          <span className="mdc-expansion__item-title">
                            Auto-pay
                          </span>
                          <span className="mdc-expansion__item-value">
                            {lease.state.info.autoPay}
                          </span>
                        </div>
                      </div>
                      <div className="pm-flex pm-flex-col">
                        <div className="pm-display">
                          <span className="mdc-expansion__item-title">
                            Allowed Payment Types
                          </span>
                          <span className="mdc-expansion__item-value">
                            {lease.state.info.allowedPaymentTypes}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="pm-flex pm-flex-2">
                      <div className="pm-flex pm-flex-col">
                        <div className="pm-display">
                          <span className="mdc-expansion__item-title">
                            Full Amount Restriction
                          </span>
                          <span className="mdc-expansion__item-value">
                            {
                              lease.state.info
                                .restrictOnlinePaymentstoFullAmount
                            }
                          </span>
                        </div>
                      </div>
                      <div className="pm-flex pm-flex-col">
                        <div className="pm-display">
                          <span className="mdc-expansion__item-title">
                            Pay All Charges Restriction
                          </span>
                          <span className="mdc-expansion__item-value">
                            {
                              lease.state.info
                                .restrictOnlinePaymentstoAllCharges
                            }
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="pm-flex pm-flex-2">
                      <div className="pm-flex pm-flex-col">
                        <div className="pm-display">
                          <span className="mdc-expansion__item-title">
                            Tenants
                          </span>
                          <span className="mdc-expansion__item-value">
                            {tenantNameList.join(', ')}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr />
                  <div className="transaction-section1 pm-padding-16">
                    <h3>Transactional Schedules</h3>
                    <table>
                      <thead>
                        <tr>
                          <th
                            data-sort-field="name"
                            onClick={lease.sortTransactionSchedulesOnClick}
                            style={{ cursor: 'pointer' }}
                          >
                            Schedule Name
                          </th>
                          <th
                            data-sort-field="start_date"
                            onClick={lease.sortTransactionSchedulesOnClick}
                            style={{ cursor: 'pointer' }}
                          >
                            Start Date
                          </th>
                          <th
                            data-sort-field="end_date"
                            onClick={lease.sortTransactionSchedulesOnClick}
                            style={{ cursor: 'pointer' }}
                          >
                            End Date
                          </th>
                          <th
                            data-sort-field="transaction_type"
                            onClick={lease.sortTransactionSchedulesOnClick}
                            style={{ cursor: 'pointer' }}
                          >
                            Transaction Type
                          </th>
                          <th
                            data-sort-field="item_types"
                            onClick={lease.sortTransactionSchedulesOnClick}
                            style={{ cursor: 'pointer' }}
                          >
                            Item Types
                          </th>
                          <th
                            data-sort-field="amount"
                            onClick={lease.sortTransactionSchedulesOnClick}
                            style={{ cursor: 'pointer' }}
                          >
                            Amount
                          </th>
                        </tr>
                      </thead>
                      <tbody>{transactionSchedules}</tbody>
                    </table>
                  </div>
                  <hr />
                  <div className="transaction-section2 pm-padding-16 pm-padding-bottom-none">
                    <h3>All Security Deposit Transactions For Tenant</h3>
                    <table>
                      <thead>
                        <tr>
                          <th>Date</th>
                          <th>Transaction Type</th>
                          <th>Tenant</th>
                          <th>Amount</th>
                        </tr>
                      </thead>
                      <tbody>
                        {securityDepositTransactions}
                        <tr>
                          <td>
                            <span>Total Security Deposit Held</span>
                          </td>
                          <td>
                            <span>&nbsp;</span>
                          </td>
                          <td>
                            <span>&nbsp;</span>
                          </td>
                          <td>
                            <span id="total-security-deposit-amount">
                              {lease.state.securityDepositHeld}
                            </span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
